import { defineComponent, reactive, toRefs } from "vue";
import service from "@/api/admin/category";
import tools from "@/utils/tools";
export default defineComponent({
  emits: ["refresh"],

  setup(props, context) {
    const state = reactive({
      form: {
        id: 0,
        categoryName: "",
        categoryIcon: ""
      },
      visible: false,
      categoryUrlItem: [],
      loading: false
    });
    const methods = {
      async getCategryIcon() {
        var res = await service.getCategoryUrl();
        state.categoryUrlItem = res.data;
        console.log(res);
      },

      findForm() {
        state.loading = true;
        service.findForm(state.form.id).then(res => {
          state.form = res.data;
          console.log(state.form);
        }).finally(() => {
          state.loading = false;
        });
      },

      saveForm() {
        service.saveForm(state.form).then(res => {
          console.log(res);

          if (res.code === 200) {
            tools.message("成功");
            state.visible = false;
            context.emit("refresh");
          }
        });
      },

      async openDialog(id) {
        state.form.id = id;
        state.visible = true;
        await methods.getCategryIcon();
        methods.findForm();
      },

      selectImg(categoryUrl) {
        state.form.categoryIcon = categoryUrl;
      }

    };
    return { ...toRefs(state),
      ...methods
    };
  }

});