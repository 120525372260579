import { defineComponent, onMounted, reactive, toRefs, ref } from "vue";
import service from "@/api/admin/category";
import Info from "./info.vue";
import tools from "@/utils/tools";
export default defineComponent({
  components: {
    Info
  },

  setup() {
    const state = reactive({
      table: {
        search: {
          categoryName: ""
        },
        page: 1,
        size: 10,
        total: 0,
        data: [],
        loading: false,
        categoryItem: [],
        tagItem: []
      }
    });
    const infoRef = ref(null);
    const methods = {
      findList() {
        state.table.loading = true;
        service.findList(state.table.page, state.table.size, state.table.search).then(res => {
          console.log(res);
          state.table.data = res.data.data;
          state.table.page = res.data.page;
          state.table.size = res.data.size;
          state.table.total = res.data.total;
        }).finally(() => {
          state.table.loading = false;
        });
      },

      deleteList(id) {
        let ids = [];

        if (id !== null) {
          ids.push(id);
        } else {
          tools.message("id不能为空", "warning");
          return;
        }

        service.deleteList(ids).then(res => {
          if (res.code === 200) {
            tools.message("删除成功");
            methods.findList();
          }
        });
      },

      handlePageChange() {
        methods.findList();
      },

      onReset() {
        state.table.search.categoryName = "";
        methods.findList();
      },

      openDialog(id) {
        infoRef.value.openDialog(id);
      }

    };
    onMounted(() => {
      methods.findList();
    });
    return { ...toRefs(state),
      ...methods,
      infoRef
    };
  }

});